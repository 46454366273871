import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';


const FourOhFour = (props) => {
	return (
		<Layout>
			<Container className='fourOhFour' id='404-page'>
				<Row>
					<Col lg={{ span: 6, offset: 3 }} md={{span: 10, offset: 1}} sm={{span: 12}}>
						<h1 className='text-center'>
							404
						</h1>
						<h3 className='text-center'>
							The page you are looking for doesn’t exist or another error occurred.
						</h3>
						<Link to={`/`} className='cta-btn'>
							<div className='cta-btn-container'>
								<button className='cta-btn-404'>
									BACK TO HOMEPAGE
								</button>
							</div>
						</Link>
					</Col>
				</Row>
			</Container>
		</Layout>
	)
}


export default FourOhFour